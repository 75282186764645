import React from "react"
import { Link, graphql } from "gatsby"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faTags, faBackward, faForward, faClock } from "@fortawesome/free-solid-svg-icons"

const Content = styled.div`
  margin: 0 auto;
  max-width: 860px;
  padding: 0 1.0875rem 1.45rem 1.0875rem;
`

const ArticleDate = styled.h2`
  margin-left: 0;
  margin-right: 12px;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 0.85028rem;
  line-height: 1.1;
  display: inline;
  color: #606060;
`

const ReadingTime = ArticleDate

const MarkerHeader = styled.h1`
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 1.6rem;
  line-height: 1.1;
  display: inline;
  border-radius: 1em 0 1em 0;
  background-image: linear-gradient(
    -100deg,
    rgba(255, 250, 150, 0.15),
    rgba(255, 250, 150, 0.8) 100%,
    rgba(255, 250, 150, 0.25)
  );
`

const PostsContainer = styled.div`
  & {
    padding: 2vh;
    margin: 0 0 8px 0;
    border-radius: 8px;
    transition: box-shadow 0.5s;
  }

  &:hover {
    box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
      0 0 0 1px rgb(10 10 10 / 2%);
  }
`

const TagContainer = styled.span`
  margin: 10px 5px;
  font-size: 20px;
  & > a {
    text-decoration: none;
  }
`

const ItemBottom = styled.div`
  display: flex;
  justify-content: space-between;
`

const ReadMore = styled.button`
  cursor: pointer;
  margin: 10px 5px;
  font-size: 20px;
  background-color: inherit;
  border-color: inherit;
  border-width: 1px;
  border-radius: 5px;
  font-size: 12px;
  white-space: pre;
  font-weight: bold;
  max-height: 27px;
  & > a {
    text-decoration: none;
  }
`

const TagsContainer = styled.span`
  padding: calc(0.5em - 1px) 0;
`

const Paginator = styled(Link)`
  border: solid 1px #606060;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: block;
  text-decoration: none;
  color: #606060;
  &:hover {
    background-color: rgba(255, 250, 150, 0.8);;
  }
`

class BlogPostList extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const { current, total } = this.props.pageContext
    const isFirst = current === 1
    const isLast = current === total
    const prevPage =
      current - 1 === 1 ? "/blog" : `/blog/` + (current - 1).toString()
    const nextPage = `/blog/` + (current + 1).toString()
    return (
      <Layout location={this.props.location}>
        <Seo title="Blog" />
        <Content>
          {posts
            .filter(({ node }) => {
              const rawDate = node.frontmatter.rawDate
              const date = new Date(rawDate)
              return date < new Date()
            })
            .map(({ node }) => (
              <PostsContainer key={node.id}>
                <Link
                  to={node.fields.slug}
                  css={css`
                    text-decoration: none;
                    color: inherit;
                  `}
                >
                  <MarkerHeader>{node.frontmatter.title}</MarkerHeader>
                </Link>
                <div>
                  <ArticleDate><FontAwesomeIcon icon={faCalendar} /> {node.frontmatter.date}</ArticleDate>
                  <ReadingTime><FontAwesomeIcon icon={faClock} /> {node.timeToRead} min read</ReadingTime>
                </div>
                <p>{node.excerpt}</p>
                <ItemBottom>
                  <TagsContainer>
                    {node.frontmatter.tags.map((tag, key) => {
                      return (
                        <TagContainer key={key}>
                          <FontAwesomeIcon icon={faTags} /> <Link to={`/tags/` + tag}>{tag}</Link>
                        </TagContainer>
                      )
                    })}
                  </TagsContainer>
                  <ReadMore>
                    <Link to={node.fields.slug}>Read More →</Link>
                  </ReadMore>
                </ItemBottom>
              </PostsContainer>
            ))}
        </Content>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            listStyle: "none",
            padding: 0,
            marginLeft: "1.45rem",
            marginBottom: "1.45rem",
          }}
        >
          {!isFirst && (
            <Paginator to={prevPage} rel="prev">
              <FontAwesomeIcon icon={faBackward} />
            </Paginator>
          )}
          {Array.from({ length: total }, (_, i) => (
            <span
              key={`pagination-number${i + 1}`}>
              <Paginator
                to={`/blog/${i === 0 ? "" : i + 1}`}
                style={{
                  textDecoration: "none",
                  background: i + 1 === current ? "rgba(255,250,150,0.8)" : "",
                }}
              >
                {i + 1}
              </Paginator>
            </span>
          ))}
          {!isLast && (
            <Paginator to={nextPage} rel="next">
              <FontAwesomeIcon icon={faForward} />
            </Paginator>
          )}
        </div>
      </Layout>
    )
  }
}

export const blogListQuery = graphql`
  query blogListQuery($skip: Int, $limit: Int) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { draft: { eq: false } } }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            tags
            title
            date(formatString: "DD MMMM, YYYY")
            rawDate: date
            path
          }
          fields {
            slug
          }
          excerpt
          timeToRead
        }
      }
    }
  }
`

export default BlogPostList
